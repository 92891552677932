<template>
  <div id="product">
    <!--头部导航-->
    <div style="z-index:9999;position:fixed;top:0;left: 0;">
      <baseNav></baseNav>
    </div>
    <!--内容-->
    <div class="productCon">
      <!--内容头部-->
      <div class="commenHead">
        <div class="about_tit_text">
          <p v-if="productData.title&&productData.title.length>0" class="animated bounceInLeft" v-html="productData.title[0].content"></p>
        </div>
        <div class="about_tit">
          <p v-if="productData.title&&productData.title.length>0" class="animated bounceInLeft" >
            {{ productData.title[0].title }}
          </p>
        </div>
        <div class="about_img">
          <div class="right_grid ">
            <img src="../assets/images/pic_grid_r.png" alt="">
          </div>
          <div class="left_grid " style="height: auto;">
            <img src="../assets/images/pic_grid_l.png" alt="">
          </div>
        </div>
      </div>

<!--      内容盒子-->
      <div class="productBox">
        <!--        列表1-->
        <div class="timeLineP timeLineP1">
          <div class="timeLineCon">
            <div class="swiper-container mySwiper"  v-show="productList1&&productList1.length>0">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item1,index) in productList1" :key="item1.id" @click="toChangeItem1(index)">
                  <div class="timeItems" :class="{timeActive:activeIndex1==index}">
                    <h3>{{item1.title}}
                      <em></em>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div style="border-bottom: 0.2777vw solid #20BFD0;width: 100vw;margin-top:-4.166vw;float:left;"></div>
          </div>
        </div>
        
        <!--        列表1内容-->
        <div class="productList" v-show="currentProduct1&&productList1.length>0">
          <div class="img">
            <img v-lazy="currentProduct1.thumb" alt="">
          </div>
          <div class="tit">
          {{currentProduct1.subtitle}}
          </div>
          <div class="con">
            {{currentProduct1.desc}}
          </div>
          <v-touch @press="press(1)" @pressup="pressup(currentProduct1.id,1)" @tap="tap(currentProduct1.id,1)">
            <div class="btn" :class="{press_btn:isPress1}">
              查看更多 <span></span>
            </div>
          </v-touch>
        </div>


        <!--        列表2-->
        <div class="timeLineP timeLineP2">
          <div class="timeLineCon">
            <div class="swiper-container mySwiper"  v-show="productList2&&productList2.length>0">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item1,index) in productList2" :key="item1.id" @click="toChangeItem2(index)">
                  <div class="timeItems" :class="{timeActive:activeIndex2==index}">
                    <h3>{{item1.title}}
                    <em></em>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div style="border-bottom: 0.2777vw solid #20BFD0;width: 100vw;margin-top:-4.166vw;float:left;"></div>
          </div>
        </div>

        <!--        列表2内容-->
        <div class="productList" v-show="currentProduct2&&productList2.length>0">
          <div class="img">
            <img v-lazy="currentProduct2.thumb" alt="">
          </div>
          <div class="tit">
            {{currentProduct2.subtitle}}
          </div>
          <div class="con">
            {{currentProduct2.desc}}
          </div>
          <v-touch @press="press(2)" @pressup="pressup(currentProduct2.id,2)" @tap="tap(currentProduct2.id,2)">
            <div class="btn" :class="{press_btn:isPress2}">
              查看更多 <span></span>
            </div>
          </v-touch>

        </div>
      </div>
      <div v-show="Show1||Show2">
        <Copyright></Copyright>
      </div>
      <div v-show="!Show1&&!Show2" style="width: 100%;position: fixed;bottom: 0; left: 50%;transform: translateX(-50%)">
        <Copyright></Copyright>
      </div>
    </div>
  </div>

</template>

<script>
import baseNav from "./baseNav";
import Copyright from "@/component/Copyright";
import Swiper from "swiper";
import {GetProductData1,GetProductData2,GetIndexDoc} from "@/api/api";
export default {
  data(){
    return{
      //标题+内容 //获取产品中心banner数据
      productData:{},
      //列表1
      productList1:[],
      // 当前商品
      currentProduct1:{},
      //列表2
      productList2:[],
      // 当前商品
      currentProduct2:{},
      //激活列表1索引
      activeIndex1:0,
      //激活列表2索引
      activeIndex2:0,
      //copyright展示
      Show1:false,
      Show2:false,
      isPress1:false,
      isPress2:false,

    }
  },
  mounted() {
    //获取产品中心banner数据
    this.toGetIndexDoc(5)
    this.toGetProductData1()
    this.toGetProductData2()

  },
  methods:{
    //获取产品中心banner数据
    toGetIndexDoc(data){
      let _this=this
      GetIndexDoc(data)
          .then(r=>{
            let res=r.data
            if(res.status!=1){
              _this.productData=[]
              return
            }
            let apiIndex=res.data
            _this.productData=apiIndex
          })
    },
    //获得列表1
    toGetProductData1(){
      let _this=this
      GetProductData1()
      .then(r=>{
        let res=r.data
        if(res.status!=1){
          _this.productList1=[]
          _this.Show1=false
          return
        }
        _this.productList1=res.data
        _this.currentProduct1=_this.productList1[0]
        _this.Show2=true
        //发展历程目录
        new Swiper('.productBox .timeLineP1 .timeLineCon .mySwiper', {
          slidesPerView: 'auto',
          observer: true,//修改swiper自己或子元素时，自动初始化swiper
          observeParents: true,//修改swiper的父元素时，自动初始化swiper
        })
      })
    },
    //改变产品
    toChangeItem1(i){
      this.activeIndex1=i
      this.currentProduct1=this.productList1[i]
    },
    //获得列表2
    toGetProductData2(){
      let _this=this
      GetProductData2()
          .then(r=>{
            let res=r.data
            if(res.status!=1){
              _this.productList2=[]
              _this.Show2=false
              return
            }
            _this.productList2=res.data
            _this.currentProduct2=_this.productList2[0]
            _this.Show2=true
            //发展历程目录
            new Swiper('.productBox .timeLineP2 .timeLineCon .mySwiper', {
              slidesPerView: 'auto',
              observer: true,//修改swiper自己或子元素时，自动初始化swiper
              observeParents: true,//修改swiper的父元素时，自动初始化swiper
            })
          })
    },
    //改变产品
    toChangeItem2(i){
      this.activeIndex2=i
      this.currentProduct2=this.productList2[i]
    },
    //点击
    press(n){
      if(n==1){
        this.isPress1=true
      }else if(n==2){
        this.isPress2=true
      }
    },
    pressup(id){
      this.$router.push('/index/product/detail.html?id='+id)

    },
    tap(id,n){
      let _this=this
      if(n==1){
        _this.isPress1=true
      }else if(n==2){
        _this.isPress2=true
      }
      setTimeout(function (){
        _this.$router.push('/index/product/detail.html?id='+id)
      },100)
    },
  },
  components:{
    baseNav,
    Copyright
  }

}
</script>
<style lang="less" scoped>
#product{
  padding-top: 13.888vw;
  background-color: #071D42;
  min-height: calc(100vh - 13.888vw);
  user-select:none;
}
.productCon{
  .commenHead{
    background:url("../assets/images/pic_banner_product.png") no-repeat 70% top;
    background-size: 156%;
    .about_tit_text p{
      margin-top: 0;
      margin-bottom:2.2222vw;
      animation-delay: 0s;
    }
    .about_tit p{
      animation-delay: 0.1s;
    }

  }
  .productBox{
    padding-bottom:5.555vw;
    .timeLineP {
      margin-top: 0;
      .timeLineCon {
        width: 100%;
        overflow: hidden;
        height: 15.277vw;
      }
      .timeLineCon .swiper-slide{
        width: auto;
        margin:0 2.777vw
      }
      .timeLineCon .swiper-slide:first-child {
        margin-left: 7.7777vw !important;
      }
      .timeLineCon .timeItems {
        display: inline-block;
        position: relative;
        height: 15.277vw;
      }
      .timeLineCon .timeItems h3 {
        line-height: 8.333vw;
        font-weight: 200;
        color: #20BFD0;
        font-size: 4.444vw;
        width: auto;
        em{
          display: block;
          position:absolute;
          bottom:-3.5vw;
          left: 0;
          width: 0;
          height: 1vw;
          transition: .2s linear all;
          background: #20BFD0;
        }
      }
      .timeLineCon .timeItems.timeActive h3 {
        font-size:4.444vw;
        font-weight: 600;
        position: relative;
        em{
          display: block;
          position:absolute;
          bottom:-3.5vw;
          left: 0;
          width: 100%;
          height: 1vw;
          transition: .2s linear all;
          background: #20BFD0;
        }
      }
    }

    .productList{
      margin:0 6.666vw;

      .img{
        width: 55.6vw;
        height: 55.6vw;
        margin-left: 50%;
        transform: translateX(-50%);
        img{
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }
      .tit{
        font-size: 3.333vw;
        line-height: 4.444vw;
        color:#fff;
        font-weight: 400;
        text-align: center;
        margin:5.555vw 0 2.2222vw;
      }
      .con{
        font-size: 3.333vw;
        line-height: 6.666vw;
        color: rgba(255, 255, 255, 0.7);
        font-weight: 400;
        margin-bottom:4.444vw;
        text-align: justify;
      }
      .btn{
        width: 100%;
        height: 11.111vw;
        margin-bottom: 13.888vw;
        font-size:3.3333vw;
        color:#20BFD0;
        text-align: center;
        line-height: 11.111vw;
        border: 0.2777vw solid #20BFD0;
        border-radius: 1.1111vw;
        span{
          display: inline-block;
          margin-left: 1.3333vw;
          width:5.555vw;
          height: 2.777vw;
          background: url("../assets/images/ico_arrow_pixel2.png") no-repeat right center;
          background-size: 100%;

        }
      }
      .press_btn{
        background: rgba(32, 191, 208, 1);
        color:rgba(7, 29, 66, 1);
        span{
          background: url("../assets/images/ico_arrow_pixel2_p.png") no-repeat right center;
          background-size: 100%;
        }
      }
    }

  }
}

</style>